import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  topMargin: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    height: '100%',
  },
  win: {
    paddingTop: 20,
    backgroundColor: '#F7F5F3',
    height: '100%',
  },
  logo: {
    height: 64,
    width: 64,
    margin: 10,
  },
  logoFull: {
    height: 128,
    width: 128,
    margin: 10,
  },
});
