import {View, Image} from 'react-native';
import * as React from 'react';
import config from '../config';
import styles from './Styles';

export default function SwitchImage(props) {
  const {index} = props;
  const icon = config.icon[index];
  return (
    <View style={styles.container}>
      <Image style={styles.logoFull} source={icon.empty} />
    </View>
  );
}
