import * as React from 'react';
import {View, Image, TouchableOpacity, SafeAreaView} from 'react-native';
import SwitchImage from './SwitchImage';
import WinImage from './WinImage';
import ActiveImage from './ActiveImage';
import styles from './Styles';
import config from '../config';

export default function Game() {
  const [time, setTime] = React.useState(0);
  const [level, setLevel] = React.useState(1);
  const [game, setGame] = React.useState(false);
  const [winGame, setWinGema] = React.useState(false);
  const [endGame, setEndGame] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [activeComputer, setActiveComputer] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState(false);

  React.useEffect(() => {
    if (game) {
      setTime(time => time + 1);
      if (time < level * 2) {
        setTimeout(() => {
          if (index === level) {
            setIndex(0);
          } else {
            setIndex(index => index + 1);
          }
        }, 500);
      } else {
        showEndGame();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, game]);

  const startAgain = () => {
    setTime(0);
    setGame(false);
    setEndGame(false);
    setIndex(0);
    setActiveComputer(false);
    setActiveUser(false);
  };

  const showEndGame = () => {
    setGame(false);
    setEndGame(true);
    setTimeout(() => {
      startAgain();
    }, 2000);
    const win = Math.floor(Math.random() * (level + 1));
    setActiveComputer(config.icon[win].name);
    if (config.icon[win].name === activeUser) {
      if (level === config.icon.length - 1) {
        setWinGema(true);
        setTimeout(() => {
          setLevel(1);
          setWinGema(false);
        }, 13000);
      } else {
        setLevel(level => level + 1);
      }
    } else {
      setLevel(1);
    }
  };

  const handlePress = image => {
    setActiveUser(image);
    setGame(true);
    setIndex(index => index + 1);
  };

  const SelectImage = props => {
    const {index, img, winGame} = props;
    let image = <></>;
    if (winGame) {
      image = <></>;
    } else if (index > level) {
      image = <></>;
    } else {
      image = (
        <TouchableOpacity onPress={() => handlePress(img.name)}>
          <Image style={styles.logo} source={img.empty} />
        </TouchableOpacity>
      );
    }
    return image;
  };

  return (
    <SafeAreaView style={winGame ? styles.win : styles.topMargin}>
      {game ? (
        <SwitchImage index={index} />
      ) : (
        <ActiveImage imageActive={activeComputer} endGame={endGame} />
      )}
      <WinImage
        activeUser={activeUser}
        activeComputer={activeComputer}
        endGame={endGame}
        game={game}
      />
      {activeUser ? (
        <ActiveImage imageActive={activeUser} endGame={!!activeUser} />
      ) : (
        <View style={styles.container}>
          {winGame && (
            <Image
              style={styles.logoFull}
              source={require('../assets/scratch.gif')}
            />
          )}
          {config.icon.map((img, index) => (
            <SelectImage key={index} img={img} index={index} winGame={winGame} />
          ))}
        </View>
      )}
    </SafeAreaView>
  );
}
