import {NativeBaseProvider, Box} from 'native-base';
import * as React from 'react';
// You can import from local files
import Game from './components/Game';

export default function App() {
  return (
    <NativeBaseProvider>
      <Box>
        <Game />
      </Box>
    </NativeBaseProvider>
  );
}
