import {View, Image} from 'react-native';
import * as React from 'react';

import config from '../config';
import styles from './Styles';
export default function ActiveImage(props) {
  const {imageActive, endGame} = props;
  const imageActiveSource = config.icon.find(el => el.name === imageActive);
  let image = <></>;
  if (endGame) {
    image = <Image style={styles.logoFull} source={imageActiveSource.full} />;
  }

  return <View style={styles.container}>{image}</View>;
}
