export default {
    icon: [    
        {
                name: 'star',
                empty: require('./assets/empty/star.png'),
                full: require('./assets/full/star.png')
        }, {
                name: 'sonne',
                empty: require('./assets/empty/sonne.png'),
                full: require('./assets/full/sonne.png')
        }, {
                name: 'car',
                empty: require('./assets/empty/car.png'),
                full: require('./assets/full/car.png')
        }, {
                name: 'fruit',
                empty: require('./assets/empty/fruit.png'),
                full: require('./assets/full/fruit.png')
        }, {
                name: 'schaf',
                empty: require('./assets/empty/schaf.png'),
                full: require('./assets/full/schaf.png')
        }, {
                name: 'rocket',
                empty: require('./assets/empty/rocket.png'),
                full: require('./assets/full/rocket.png')
        }, {
                name: 'beeren',
                empty: require('./assets/empty/beeren.png'),
                full: require('./assets/full/beeren.png')
        }, {
                name: 'cup',
                empty: require('./assets/empty/cup.png'),
                full: require('./assets/full/cup.png')
        }, {
                name: 'bier',
                empty: require('./assets/empty/bier.png'),
                full: require('./assets/full/bier.png')
        }, {
                name: 'helicopter',
                empty: require('./assets/empty/helicopter.png'),
                full: require('./assets/full/helicopter.png')
        }, {
                name: 'apple',
                empty: require('./assets/empty/apple.png'),
                full: require('./assets/full/apple.png')
        }, {
                name: 'wine',
                empty: require('./assets/empty/wine.png'),
                full: require('./assets/full/wine.png')
        }
    ]
}
