import {View, Image} from 'react-native';
import * as React from 'react';
import styles from './Styles';

export default function WinImage(props) {
  const {activeUser, activeComputer, endGame, game} = props;
  let image;
  if (endGame) {
    if (activeUser === activeComputer) {
      image = (
        <Image style={styles.logoFull} source={require('../assets/yes.png')} />
      );
    } else {
      image = (
        <Image style={styles.logoFull} source={require('../assets/no.png')} />
      );
    }
  } else {
    if (game) {
      image = (
        <Image
          style={styles.logoFull}
          source={require('../assets/loading.gif')}
        />
      );
    } else {
      image = <></>;
    }
  }

  return <View style={styles.container}>{image}</View>;
}
